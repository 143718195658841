<template>
  <b-dropdown
    variant="outline-secondary"
    class=""
  >
    <template #button-content>
      <b-badge
        v-if="selectedResourcesIds && selectedResourcesIds.length > 0"
        variant="primary"
        pill
      >
        {{ selectedResourcesIds.length }}
      </b-badge>

      Terrains
    </template>

    <b-dropdown-form form-class="pl-3">
      <b-checkbox-group
        v-model="selected"
        :checked="selected"
        :options="formattedResources"
        stacked
      />
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import debounce from '../../../helpers/debounce';
import { mapState } from 'vuex/dist/vuex.common.js';

export default {
  name: 'CalendarResourceSelector',
  props: {
    partnerId: {
      type: Number,
      required: false,
      default: undefined,
    },
    sports: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapState('calendar', ['resources']),
    selectedResourcesIds() {
      return new URLSearchParams(window.location.search).getAll('fields[]');
    },
    formattedResources() {
      return this.resources.map((r) => ({ text: r.name, value: r.id }));
    },
  },
  watch: {
    selected(a, b) {
      if (a !== b && a !== this.selectedResourcesIds) {
        this.debouncedHandleSubmit();
      }
    },
  },
  async created() {
    this.selected = this.selectedResourcesIds;
    this.debouncedHandleSubmit = debounce(this.handleSubmit, 800);
  },
  methods: {
    handleSubmit() {
      const url = new URL(window.location);
      const params = new URLSearchParams(window.location.search);
      const key = 'fields[]';

      console.log('handleSubmit', this.selected);

      params.delete(key);
      this.selected.forEach((s) => params.append(key, s));

      // console.dir(params);

      url.search = params.toString();
      window.location.assign(url);
    },
  },
};
</script>
