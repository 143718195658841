<template>
  <b-datepicker
    v-model="value"
    start-weekday="1"
    :initial-date="value"
    value-as-date
    button-only
    dropleft
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'GetParamDatepicker',
  props: {
    initialValueName: { type: String, required: false, default: 'defaultDatepickerDate' },
    getParamName: { type: String, required: false, default: 'start_date' },
  },
  data() {
    return {
      value: window[this.initialValueName],
    };
  },
  methods: {
    handleInput(v) {
      const dd = v.getDate().toString().padStart(2, '0');
      const mm = (v.getMonth() + 1).toString().padStart(2, '0');
      const yyyy = v.getFullYear().toString().padStart(2, '0');
      const newDate = `${dd}-${mm}-${yyyy}`;

      const newSearch = new URLSearchParams(window.location.search);
      newSearch.set(this.getParamName, newDate);
      newSearch.delete('allocation');
      newSearch.delete('action');

      window.location.search = newSearch.toString();
    },
  },
};
</script>
